import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "second-batch"
    }}>{`Second batch`}</h1>
    <h2 {...{
      "id": "malt"
    }}>{`Malt`}</h2>
    <p>{`base malt: `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Pale ale malt 6800g - 2137g + 489g`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Chinese Pilsner malt 720g`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Caramel Red Malt 720g`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Aroma Malt 140g`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Chocolate Malt 140g`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "hops"
    }}>{`Hops`}</h2>
    <p>{`Two fermentor, fast fermentor 11 Liter we call f1, carboy we call f2.`}</p>
    <p>{`f1 start actively after 8 hour, while the f2 start actively after 11 hours.`}</p>
    <ul>
      <li parentName="ul">{`Firstly the bubble choke at f1, then everything is ok, even through there are lots of foams at the head space.`}</li>
      <li parentName="ul">{`Probably because f2 has less head space, the foams are really strong, they even get out of the fermentor. Then I have to do the top cropping.`}</li>
    </ul>
    <p>{`With less flocculent yeast, better with top cropping.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      